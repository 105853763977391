<template>
  <div class="view-container">
    <div class="view">
      <div class="view-heading">
        <div class="view-title">
          <label v-if="type !== 'core'">{{ $t('issue.filename') }}
            <el-input v-model="model.filename"></el-input>
          </label>
          <label v-else>{{ model.filename }}</label>
        </div>
      </div>
      <div class="view-body json-editor">
        <json-editor
            :type="type"
            ref="jsonEditor"
            v-model="value"
        ></json-editor>
      </div>
    </div>
    <div class="box-button align-right">
      <el-button
          @click.prevent.stop="onSave"
          type="primary"
          v-if="type !== 'core'"
      >{{ $t('system.save') }}
      </el-button>
      <el-button @click.prevent.stop="backTo">{{ $t('system.cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import JsonEditor from '../../components/JsonEditor/index.vue';
import notification from '../../notification/notify';

export default {
  name: 'JsonForm',
  components: {
    JsonEditor,
  },
  beforeMount() {
    this.fetchData();
  },
  mounted() {
  },
  watch: {
    '$route': 'fetchData',
  },
  data() {
    return {
      value: {},
      model: {},
      type: '',
    };
  },
  methods: {
    backTo: function () {
      window.history.back();
    },
    fetchData() {
      var vm = this;
      let store = `json/${vm.$route.params.jsonId}`;
      let path = null;
      if (this.$store.state.userModel === 'SaaSUser') {
        store = `saas/json/${vm.$route.params.jsonId}`;
        path = vm.$store.state.filePath;
      }
      axios.get(`${store}`, {
        params: {
          path,
        },
      })
          .then(function (response) {
            Vue.set(vm.$data, 'type', response.data.type);
            Vue.set(vm.$data, 'value', response.data.schema);
            Vue.set(vm.$data, 'model', response.data.model);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                vm.$t(error.response.data.error),
                'error');
          });

      this.loading = false;
    },
    onSave() {
      let vm = this;
      let store = null;
      let path = null;
      if (this.$store.state.userModel === 'SaaSUser') {
        store = `saas/json/${vm.$route.params.jsonId}`;
        path = vm.$store.state.filePath;
      } else {
        store = `json/${vm.$route.params.jsonId}`;
      }
      axios.put(`${store}`, {
        path,
        'data': JSON.stringify(vm.value),
        'model': vm.model,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(function (response) {
            notification.notify(
                vm.$t('notify.success'),
                vm.$t('system.saved'),
                'success');
            vm.fetchData();
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                vm.$t(error.response.data.error),
                'error');
          });
    },
  },
};
</script>

<style>
.wrap-link {
  word-wrap: break-word;
}
</style>
