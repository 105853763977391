<!--/* eslint-disable no-underscore-dangle,import/newline-after-import */-->
<template>
  <div class="json-editor">
    <textarea ref="textarea"></textarea>
  </div>
</template>
<script>
/* eslint-disable */
import CodeMirror from 'codemirror';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/rubyblue.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';

require('script-loader!jsonlint');

export default {
  name: 'jsonEditor',
  data() {
    return {
      jsonEditor: false,
      readOnly: false,
    };
  },
  props: ['value', 'type'],
  watch: {
    value(value) {
      const editorValue = this.jsonEditor.getValue();
      if (value !== editorValue) {
        this.jsonEditor.setValue(JSON.stringify(this.value, null, 2));
      }
    },
    type(value) {
      this.jsonEditor.options.readOnly = value === 'core' ? true : false;
    },
  },
  mounted() {
    this.jsonEditor = CodeMirror.fromTextArea(this.$refs.textarea, {
      lineNumbers: true,
      mode: 'application/json',
      gutters: ['CodeMirror-lint-markers'],
      theme: 'rubyblue',
      lint: true,
    });

    this.jsonEditor.setValue(JSON.stringify(this.value, null, 2));
    this.jsonEditor.on('change', cm => {
      this.$emit('changed', cm.getValue());
      this.$emit('input', cm.getValue());
    });
  },
  methods: {
    getValue() {
      return this.jsonEditor.getValue();
    },
  },
};
</script>

<style scoped>
.json-editor {
  height: 100%;
  position: relative;
}

.json-editor >>> .CodeMirror {
  height: auto;
  min-height: 300px;
}

.json-editor >>> .CodeMirror-scroll {
  min-height: 300px;
}

.json-editor >>> .cm-s-rubyblue span.cm-string {
  color: #F08047;
}
</style>
